type WindowMessage = {
  event:
    | "scheduled"
    | "cancelled"
    | "selected_date"
    | "next_page"
    | "previous_page";
};

export const sendMessage = (event: WindowMessage) => {
  window.parent !== window && window.parent.postMessage(event, "*");
};
