import { useParticipant } from "@livekit/react-core";
import { LocalVideoTrack, Room } from "livekit-client";
import { useEffect, useState } from "react";

import { ReactComponent as flipIcon } from "./icons/flip.svg";
import { ReactComponent as MicOffIcon } from "./icons/micOff.svg";
import { ReactComponent as MicOnIcon } from "./icons/micOn.svg";
import { ReactComponent as PhoneIcon } from "./icons/phone.svg";
import { ReactComponent as ScreenShareIcon } from "./icons/screenShare.svg";
import { ReactComponent as SubtitlesIcon } from "./icons/subtitles.svg";
import { ReactComponent as VideoOffIcon } from "./icons/videoOff.svg";
import { ReactComponent as VideoOnIcon } from "./icons/videoOn.svg";
import {
  flex,
  flexCenter,
  fullSize,
  TOOLBAR_BUTTON_PADDING_LARGE,
  TOOLBAR_BUTTON_PADDING_MINIMIZED,
  TOOLBAR_BUTTON_SIZE_LARGE,
  TOOLBAR_BUTTON_SIZE_MINIMIZED,
  TOOLBAR_SPACING_LARGE,
  TOOLBAR_SPACING_MINIMIZED,
} from "./styles";

export const LivekitToolbar = ({
  room,
  minimized,
  onClose,
  onMissingPermissions,
  toggleTranscript,
}: {
  room: Room;
  minimized?: boolean;
  onClose?: () => void;
  onMissingPermissions?: (type: "MICROPHONE" | "CAMERA" | "SCREEN") => void;
  toggleTranscript?: () => void;
}) => {
  const { microphonePublication, cameraPublication, screenSharePublication } =
    useParticipant(room.localParticipant);
  const microphoneEnabled = !(microphonePublication?.isMuted ?? true);
  const cameraEnabled = !(cameraPublication?.isMuted ?? true);
  const screenShareEnabled = !(screenSharePublication?.isMuted ?? true);

  const [supportsFacingMode, setSupportsFacingMode] = useState(false);
  useEffect(() => {
    // Only show the camera switcher if the browser supports selecting a camera
    // based on the direction it faces, and there are at least two cameras.
    //
    // We wait until there is a video track to call `Room.getLocalDevices` to
    // avoid showing a permissions prompt to the user twice.
    //
    // noinspection PointlessBooleanExpressionJS
    const checkSupportsFacingMode = async () =>
      !!cameraPublication?.videoTrack &&
      !!navigator.mediaDevices.getSupportedConstraints().facingMode &&
      (await Room.getLocalDevices("videoinput")).length >= 2;

    checkSupportsFacingMode().then(setSupportsFacingMode);
  }, [cameraPublication, setSupportsFacingMode]);

  const toggleMicrophone = () => {
    room.localParticipant
      .setMicrophoneEnabled(!microphoneEnabled)
      .catch(() => onMissingPermissions?.("MICROPHONE"));
  };
  const toggleCamera = () => {
    room.localParticipant
      .setCameraEnabled(!cameraEnabled)
      .catch(() => onMissingPermissions?.("CAMERA"));
  };
  const toggleScreenShare = () => {
    room.localParticipant
      .setScreenShareEnabled(!screenShareEnabled)
      .catch(() => onMissingPermissions?.("SCREEN"));
  };
  const toggleFacingMode = () => {
    // We can't assume that the only two facing modes available on the device
    // are "user" and "environment", so we just ask the device for any facing
    // mode different from the current one.
    const currentFacingMode =
      cameraPublication?.track?.mediaStreamTrack.getSettings().facingMode;
    const otherFacingModes = ["user", "environment", "left", "right"].filter(
      (it) => it !== currentFacingMode,
    );

    // Merge the default options to match the behavior of the initial call to
    // `enableCameraAndMicrophone()` in `LivekitVideoRenderer`, otherwise the
    // video aspect ratio might change after toggling the facing mode.
    (cameraPublication?.videoTrack as LocalVideoTrack | undefined)
      ?.restartTrack({
        ...room.options.videoCaptureDefaults,
        facingMode: otherFacingModes as any,
      })
      .then();
  };

  return (
    <div style={{ ...flex }}>
      <LivekitButton
        Icon={microphoneEnabled ? MicOnIcon : MicOffIcon}
        onClick={toggleMicrophone}
        minimized={minimized}
      />
      <LivekitButton
        Icon={cameraEnabled ? VideoOnIcon : VideoOffIcon}
        onClick={toggleCamera}
        minimized={minimized}
      />
      <LivekitButton
        Icon={ScreenShareIcon}
        onClick={toggleScreenShare}
        minimized={minimized}
        active={screenShareEnabled}
      />
      {cameraEnabled && supportsFacingMode && (
        <LivekitButton
          Icon={flipIcon}
          onClick={toggleFacingMode}
          minimized={minimized}
        />
      )}
      {toggleTranscript && (
        <LivekitButton
          Icon={SubtitlesIcon}
          onClick={toggleTranscript}
          minimized={minimized}
        />
      )}
      {onClose && (
        <LivekitButton
          Icon={PhoneIcon}
          danger
          onClick={onClose}
          minimized={minimized}
        />
      )}
    </div>
  );
};

const LivekitButton = ({
  Icon,
  danger,
  active,
  onClick,
  minimized,
}: {
  Icon: React.ComponentType<React.ComponentProps<"svg">>;
  danger?: boolean;
  active?: boolean;
  onClick?: () => void;
  minimized?: boolean;
}) => (
  <button
    style={{
      ...flexCenter,
      width: minimized
        ? TOOLBAR_BUTTON_SIZE_MINIMIZED
        : TOOLBAR_BUTTON_SIZE_LARGE,
      height: minimized
        ? TOOLBAR_BUTTON_SIZE_MINIMIZED
        : TOOLBAR_BUTTON_SIZE_LARGE,
      padding: minimized
        ? TOOLBAR_BUTTON_PADDING_MINIMIZED
        : TOOLBAR_BUTTON_PADDING_LARGE,
      marginLeft: 7,
      marginRight: minimized
        ? TOOLBAR_SPACING_MINIMIZED
        : TOOLBAR_SPACING_LARGE,
      borderRadius: 40,
      color: danger || active ? "#ffffff" : "#6d758d",
      backgroundColor: danger ? "#ff323d" : active ? "#0013FF" : "#ffffff",
    }}
    onClick={onClick}
  >
    <Icon style={{ ...fullSize }} />
  </button>
);
