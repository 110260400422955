/**
 * Screen sizes for Tailwind breakpoints.
 * Factored out to be shared with the [useCurrentScreen] hook.
 */
const breakpoints = {
  sm: "640px",
  md: "768px",
  lg: "1024px",
};

module.exports = breakpoints;
