import { CheckCircleIcon } from "@heroicons/react/outline";
import React from "react";
import { useOutletContext } from "react-router-dom";

import { AppContext } from "../App";
import { AppointmentDetails } from "../components/AppointmentDetails";
import { SecondaryLink } from "../components/Button";
import Layout from "../components/Layout";
import { useIntl } from "../locale";

export const FinalizedPage = () => {
  const intl = useIntl();
  const { apiData } = useOutletContext<AppContext>();

  const { scheduledAt, finalizedAt, doctor, attachments } =
    apiData.invitationState;

  if (!scheduledAt || !finalizedAt || !doctor) return <></>;

  return (
    <Layout>
      <div className="flex flex-col max-w-lg w-full self-center flex-grow">
        <div className="flex-center space-x-3 sm:space-x-4 text-primary text-lg sm:text-xl font-medium">
          <CheckCircleIcon className="h-10 sm:h-12" />
          <div>
            {intl({
              en: "Your appointment is finished",
              fr: "Votre rendez-vous est terminé",
              pt: "Sua consulta está concluída",
            })}
          </div>
        </div>

        <AppointmentDetails
          startTime={scheduledAt}
          endTime={finalizedAt}
          hidePatientDetails
          patientFirstName={apiData.patientSummary.firstName}
          patientLastName={apiData.patientSummary.lastName}
          patientPhone={apiData.patientSummary.phone}
          doctorName={doctor.displayName}
          doctorTitle={doctor.title}
          doctorAvatarUrl={doctor.avatarUrl}
          className="sm:my-8"
        />

        {attachments && attachments.length > 0 && (
          <>
            <h3 className="text-sm font-semibold mb-4">
              {intl({
                en: "Files shared with you",
                fr: "Fichiers partagés avec vous",
                pt: "Arquivos compartilhados com você",
              })}
            </h3>
            <div className="flex flex-col flex-grow space-y-2">
              {attachments.map((attachment) => (
                <SecondaryLink
                  key={attachment.fileUpload.urlV2.url}
                  href={attachment.fileUpload.urlV2.url}
                  target="_blank"
                >
                  {attachment.title}
                </SecondaryLink>
              ))}
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};
