import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { App } from "./App";
import { environment } from "./environment";
import { CallPage } from "./pages/CallPage";
import { CancelConfirmPage } from "./pages/CancelConfirmPage";
import { CancelledPage } from "./pages/CancelledPage";
import { FinalizedPage } from "./pages/FinalizedPage";
import { IndexPage } from "./pages/IndexPage";
import { ScheduleConfirmPage } from "./pages/ScheduleConfirmPage";
import { SchedulePage } from "./pages/SchedulePage";
import { SummaryPage } from "./pages/SummaryPage";
import "./styles/index.css";

// Sentry error monitoring.
Sentry.init({
  dsn: "https://2d6e90f2cd3a40b28476c1d733a98e7a@sentry-relay.nabla.com/4504526307065856",
  environment: environment(),
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path=":token" element={<App />}>
          <Route path="schedule" element={<SchedulePage />} />
          <Route path="schedule/:timestamp" element={<ScheduleConfirmPage />} />
          <Route path="summary" element={<SummaryPage />} />
          <Route path="summary/cancel" element={<CancelConfirmPage />} />
          <Route path="summary/call" element={<CallPage />} />
          <Route path="cancelled" element={<CancelledPage />} />
          <Route path="finalized" element={<FinalizedPage />} />
        </Route>
        <Route path="/" element={<IndexPage />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
);
