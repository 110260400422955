import React, { useEffect } from "react";
import { Outlet, useParams, useLocation } from "react-router-dom";

import { ApiHookOutput, ApiData, useApi } from "./api";
import Layout from "./components/Layout";
import { Page } from "./components/Page";
import { Spinner } from "./components/Spinner";
import { useSafeNavigate } from "./hooks";
import { getBrowserLocale, LocaleContextProvider } from "./locale";

export type AppContext = Omit<ApiHookOutput, "apiState"> & {
  apiData: ApiData;
};

export const App = () => {
  const { token } = useParams();
  const { apiState, ...apiRest } = useApi(token as string);
  const navigate = useSafeNavigate();
  const location = useLocation();

  const invitationState =
    apiState.status === "SUCCESS"
      ? apiState.data.invitationState.__typename
      : undefined;

  // Force-redirect to the right path depending on the current state.
  useEffect(
    () => {
      const navigateIfNeeded = (to: string) => {
        if (location.pathname.startsWith(`/${token}/${to}`)) return;
        navigate(to, { replace: true });
      };

      switch (invitationState) {
        case "InvitationCreated":
          return navigateIfNeeded("schedule");
        case "WebCallScheduled":
          return navigateIfNeeded("summary");
        case "WebCallCancelled":
          return navigateIfNeeded("cancelled");
        case "WebCallFinalized":
          return navigateIfNeeded("finalized");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [invitationState],
  );

  return (() => {
    switch (apiState.status) {
      case "SUCCESS":
        return (
          <LocaleContextProvider
            rawLocale={apiState.data.patientSummary.locale}
          >
            {apiState.data.configuration.organizationName ===
              "Chanel Skincare" && (
              // FIXME(@liautaud): Remove this after the Chanel demo.
              /* language=css */
              <style>{`
                  body {
                      font-family: Helvetica, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
                  }

                  @font-face {
                      font-family: abchanel-corpo;
                      font-display: swap;
                      src: url(https://fonts.chanel.com/k/64b14ed9188172fd562480b882a998c1cb8e8eb2-l.woff2) format("woff2"), url(https://fonts.chanel.com/k/64b14ed9188172fd562480b882a998c1cb8e8eb2-d.woff) format("woff"), url(https://fonts.chanel.com/k/64b14ed9188172fd562480b882a998c1cb8e8eb2-a.otf) format("opentype");
                      font-style: normal;
                      font-weight: 300
                  }

                  :root {
                      --color-primary: 0 0 0 !important;
                  }

                  h3.text-lg.leading-6.font-medium {
                      font-family: abchanel-corpo, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
                      font-weight: 300;
                  }

                  button.rounded-lg, button.rounded-3xl {
                      border-radius: 0;
                  }

                  button.border.border-transparent.rounded-lg.text-primary.bg-primary\\/10.hover\\:bg-primary\\/20.focus\\:ring-primary\\/40 {
                      background-color: transparent;
                      border: 1px solid #000;
                  }

                  button.border.border-transparent.rounded-lg.text-primary.bg-primary\\/10.hover\\:bg-primary\\/20.focus\\:ring-primary\\/40:hover {
                      background-color: #f0f0f0;
                  }
              `}</style>
            )}
            <Page {...apiState.data.configuration}>
              <Outlet context={{ ...apiRest, apiData: apiState.data }} />
            </Page>
          </LocaleContextProvider>
        );
      case "LOADING":
        return (
          <Page>
            <Spinner />
          </Page>
        );
      case "ERROR":
        return (
          <Page>
            <Layout>
              <p className="text-center">
                {(() => {
                  switch (getBrowserLocale()) {
                    case "ENGLISH":
                      return "An error occurred while loading the page. Please try again later.";
                    case "FRENCH":
                      return "Une erreur s’est produite lors du chargement de la page. Veuillez réessayer ultérieurement.";
                    case "PORTUGUESE":
                      return "Ocorreu um erro durante o carregamento da página. Por favor, tente novamente mais tarde.";
                  }
                })()}
              </p>
            </Layout>
          </Page>
        );
    }
  })();
};
