import classNames from "classnames";
import React from "react";

export const PrimaryButton = ({
  label,
  className,
  ...rest
}: { label: string } & Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  "children"
>) => (
  <button
    className={classNames(
      "text-center items-center px-4 py-3 border border-transparent font-medium rounded-3xl text-white bg-primary/100 hover:bg-primary/75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary/40 disabled:bg-primary/50",
      className,
    )}
    {...rest}
  >
    {label}
  </button>
);

export const SecondaryButton = ({
  label,
  className,
  ...rest
}: { label: string } & Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  "children"
>) => (
  <button
    className={classNames(
      "text-center items-center px-4 py-3 border border-text-lighter font-medium rounded-3xl text-text-light hover:bg-white/5 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500",
      className,
    )}
    {...rest}
  >
    {label}
  </button>
);

export const SecondaryLink = ({
  className,
  children,
  ...rest
}: React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>) => (
  <a
    className={classNames(
      "text-center items-center px-4 py-3 border border-text-lighter font-medium rounded-3xl text-text-light hover:bg-white/5 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500",
      className,
    )}
    {...rest}
  >
    {children}
  </a>
);
