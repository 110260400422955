import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

import { AppContext } from "../App";
import { LivekitRoomViewer } from "../components/LivekitRoomViewer/LivekitRoomViewer";
import { Spinner } from "../components/Spinner";
import { useSafeNavigate } from "../hooks";
import { useIntl } from "../locale";

export const CallPage = () => {
  const { apiData } = useOutletContext<AppContext>();
  const [firstLivekitToken, setFirstLivekitToken] = useState<string>();
  const { livekitUrl, livekitToken } = apiData.invitationState;
  const intl = useIntl();
  const navigate = useSafeNavigate();

  // The token is refreshed regularly, but we only want to keep the first valid
  // one to avoid destroying and re-creating the room unnecessarily every time.
  useEffect(() => {
    if (!firstLivekitToken) setFirstLivekitToken(livekitToken);
  }, [firstLivekitToken, livekitToken]);

  return (
    <>
      {livekitUrl && firstLivekitToken && (
        <div className="fixed inset-0 w-full h-full z-10 bg-black">
          <LivekitRoomViewer
            url={livekitUrl}
            token={firstLivekitToken}
            onClose={() => navigate("../summary")}
            onMissingPermissions={(type) =>
              alert(
                (() => {
                  switch (type) {
                    case "CAMERA":
                      return intl({
                        en: "This tab is not allowed to access your camera, please grant the right permissions.",
                        fr: "Cet onglet n’a pas accès à votre caméra, veuillez accorder les autorisations nécessaires.",
                        pt: "Esta guia não tem permissão para acessar sua câmera, conceda as permissões corretas.",
                      });
                    case "MICROPHONE":
                      return intl({
                        en: "This tab is not allowed to access your microphone, please grant the right permissions.",
                        fr: "Cet onglet n’a pas accès à votre microphone, veuillez accorder les autorisations nécessaires.",
                        pt: "Esta guia não tem permissão para acessar seu microfone, conceda as permissões corretas.",
                      });
                    case "SCREEN":
                      return intl({
                        en: "This tab is not allowed to access your screen, please grant the right permissions or try sharing a single window.",
                        fr: "Cet onglet n’a pas accès à votre écran, veuillez accorder les autorisations nécessaires ou essayez de partager une fenêtre seulement.",
                        pt: "Esta guia não tem permissão para acessar sua tela, conceda as permissões corretas ou tente compartilhar uma única janela.",
                      });
                  }
                })(),
              )
            }
            emptyRoomLabel={intl({
              en: "Waiting for the other participant to join",
              fr: "En attente de l'autre participant",
              pt: "Aguardando o outro participante entrar",
            })}
          />
        </div>
      )}
      <Spinner />
    </>
  );
};
