import {
  ArrowDownIcon,
  ArrowUpIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/outline";
import classNames from "classnames";
import React, { MouseEventHandler, useState } from "react";
import { useOutletContext } from "react-router-dom";

import { AppContext } from "../App";
import { AvailableSlot } from "../api";
import { useCurrentDate, useMatchesScreen } from "../hooks";
import { sendMessage } from "../iframe";
import { useDateTimeIntl, useIntl } from "../locale";
import { useSettings } from "../settings";
import { capitalize, plusDays, startOfDay } from "../utils";

const MOBILE_COLUMN_COUNT = 3;
const TABLET_COLUMN_COUNT = 5;
const DESKTOP_COLUMN_COUNT = 7;

export const SlotPicker = ({
  slots,
  onPick,
}: {
  slots: { day: Date; slots: AvailableSlot[] }[];
  onPick?: (time: Date) => void;
}) => {
  const intl = useIntl();
  const { toShortWeekday, toShortMonthDay, toHoursMinutes } = useDateTimeIntl();
  const { setSlotsStartDate } = useOutletContext<AppContext>();
  const { minimizedRows } = useSettings();

  const currentDate = useCurrentDate();
  const currentDay = startOfDay(currentDate);

  const isLargeScreen = useMatchesScreen("lg");
  const isMediumScreen = useMatchesScreen("md");
  const columnCount = isLargeScreen
    ? DESKTOP_COLUMN_COUNT
    : isMediumScreen
    ? TABLET_COLUMN_COUNT
    : MOBILE_COLUMN_COUNT;

  const [isMinimized, setIsMinimized] = useState(true);

  const firstDisplayedDay = startOfDay(slots[0].day);

  const currentDays = slots.slice(0, columnCount);
  const needsShowMore = currentDays.some(
    ({ slots }) => slots.length > minimizedRows,
  );

  const previousPage = () => {
    const targetDay = plusDays(firstDisplayedDay, -columnCount);
    sendMessage({ event: "previous_page" });
    setSlotsStartDate(
      targetDay.getTime() >= currentDay.getTime() ? targetDay : currentDay,
    );
  };

  const nextPage = () => {
    sendMessage({ event: "next_page" });
    setSlotsStartDate(plusDays(firstDisplayedDay, columnCount));
  };

  return (
    <div className="flex flex-col min-h-full flex-grow justify-between">
      <div className="flex flex-row items-start">
        <button
          className={classNames(
            "-ml-2 mt-3 text-text-lighter hover:opacity-80",
            {
              invisible: firstDisplayedDay.getTime() <= currentDay.getTime(),
            },
          )}
          onClick={previousPage}
        >
          <ChevronLeftIcon className="h-5" />
        </button>

        <div
          className="grid gap-2 sm:gap-3 lg:gap-4 auto-rows-min flex-1"
          style={{
            gridTemplateColumns: `repeat(${columnCount}, minmax(0, 1fr))`,
          }}
        >
          {currentDays.map(({ day, slots }) => (
            <div
              className="text-center select-none flex flex-col"
              key={day.valueOf()}
            >
              <h2 className="text-text font-medium">
                {capitalize(toShortWeekday(day))}
              </h2>
              <div className="text-text-light">
                {capitalize(toShortMonthDay(day))}
              </div>

              {slots.length === 0 ? (
                <p className="text-text-lighter/80 my-5">
                  {intl({
                    en: "No slots available",
                    fr: "Aucun créneau disponible",
                    pt: "Nenhum horário disponível",
                  })}
                </p>
              ) : (
                <div className="flex flex-col mt-5 space-y-2">
                  {slots
                    .map(({ start }) => {
                      const label = toHoursMinutes(start);
                      return (
                        <SlotButton
                          label={label}
                          key={label}
                          onClick={() => onPick?.(start)}
                        />
                      );
                    })
                    .slice(0, isMinimized ? minimizedRows : undefined)}
                </div>
              )}
            </div>
          ))}
        </div>
        <button
          className="-mr-2 mt-3 text-text-lighter hover:opacity-80"
          onClick={nextPage}
        >
          <ChevronRightIcon className="h-5" />
        </button>
      </div>

      {needsShowMore && (
        <div className="flex-center mt-7">
          <MoreButton
            minimized={isMinimized}
            onClick={() => setIsMinimized((m) => !m)}
          />
        </div>
      )}
    </div>
  );
};

const SlotButton = ({
  label,
  onClick,
}: {
  label: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}) => (
  <button
    type="button"
    className="px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-lg text-primary bg-primary/10 hover:bg-primary/20 focus:outline-none focus:ring-2 focus:ring-primary/40"
    onClick={onClick}
  >
    {label}
  </button>
);

const MoreButton = ({
  minimized,
  onClick,
}: {
  minimized: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}) => {
  const intl = useIntl();
  const label = minimized
    ? intl({ en: "Show more", fr: "Voir plus", pt: "Ver mais" })
    : intl({ en: "Show less", fr: "Voir moins", pt: "Ver menos" });
  const Icon = minimized ? ArrowDownIcon : ArrowUpIcon;
  return (
    <button
      type="button"
      className="flex-center space-x-2 font-medium font-medium text-text-light"
      onClick={onClick}
    >
      <Icon className="h-4" />
      <div>{label}</div>
    </button>
  );
};
