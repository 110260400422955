import { ChevronLeftIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import React from "react";

import { useSafeNavigate } from "../hooks";
import { useIntl } from "../locale";
import { DisplayMode, useSettings } from "../settings";

const Layout = ({
  title,
  showBack,
  children,
}: {
  title?: string;
  showBack?: boolean;
  children: React.ReactNode;
}) => {
  const navigate = useSafeNavigate();
  const intl = useIntl();
  const { displayMode } = useSettings();
  const isPageMode = displayMode === DisplayMode.PAGE;

  return (
    <div
      className={classNames(
        "bg-card shadow divide-y divide-divider flex-grow flex flex-col",
        { "sm:rounded-lg": isPageMode },
      )}
    >
      {title && (
        <div className="px-4 py-5 sm:px-6 flex items-center">
          {showBack && (
            <button
              className="absolute flex-center py-2 space-x-2 text-text-light"
              onClick={() => navigate(-1)}
            >
              <ChevronLeftIcon height={20} />
              <span className="hidden sm:block">
                {intl({ en: "Back", fr: "Retour", pt: "Voltar" })}
              </span>
            </button>
          )}

          <h3 className="text-lg leading-6 font-medium text-text text-center flex-grow">
            {title}
          </h3>
        </div>
      )}
      <div className="px-4 py-5 sm:p-6 flex flex-col flex-grow justify-center">
        {children}
      </div>
    </div>
  );
};

export default Layout;
