import React from "react";
import { useOutletContext } from "react-router-dom";

import { AppContext } from "../App";
import { PrimaryButton } from "../components/Button";
import Layout from "../components/Layout";
import { useDateTimeIntl, useIntl } from "../locale";

export const CancelConfirmPage = () => {
  const { apiData, mutations } = useOutletContext<AppContext>();
  const intl = useIntl();
  const { toFullDate } = useDateTimeIntl();

  if (!apiData.invitationState.scheduledAt) return <></>;
  const appointmentTime = new Date(apiData.invitationState.scheduledAt);

  return (
    <Layout
      title={intl({
        en: "Cancel your appointment",
        fr: "Annuler votre rendez-vous",
        pt: "Cancele sua consulta",
      })}
      showBack
    >
      <div className="flex flex-col max-w-lg w-full self-center flex-grow">
        <p className="text-text-light text-center mt-4 mb-10">
          {intl({
            en: `Are you sure you want to cancel your ${toFullDate(
              appointmentTime,
            )} appointment?`,
            fr: `Êtes-vous sûr de vouloir annuler votre rendez-vous du ${toFullDate(
              appointmentTime,
            )} ?`,
            pt: `Tem certeza de que deseja cancelar sua consulta de ${toFullDate(
              appointmentTime,
            )}?`,
          })}
        </p>

        <PrimaryButton
          label={intl({
            en: "Cancel the appointment",
            fr: "Annuler le rendez-vous",
            pt: "Cancelar a consulta",
          })}
          onClick={mutations.cancelSlot}
        />
      </div>
    </Layout>
  );
};
