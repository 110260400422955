import React from "react";

import Layout from "../components/Layout";
import { useIntl } from "../locale";

export const CancelledPage = () => {
  const intl = useIntl();
  return (
    <Layout
      title={intl({
        en: "Appointment cancelled",
        fr: "Rendez-vous annulé",
        pt: "Consulta cancelada",
      })}
    >
      <div className="flex flex-col max-w-lg w-full self-center flex-grow">
        <p className="text-text-light text-center my-3">
          {intl({
            en: "Your appointment was cancelled. Please use a new booking link to schedule a new one.",
            fr: "Votre rendez-vous a été annulé. Merci d’utiliser un nouveau lien de réservation pour en prendre un nouveau.",
            pt: "Sua consulta foi cancelada. Por favor, use um novo link de reserva para agendar um novo.",
          })}
        </p>
      </div>
    </Layout>
  );
};
