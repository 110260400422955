import React from "react";
import { useOutletContext } from "react-router-dom";

import { AppContext } from "../App";
import Layout from "../components/Layout";
import { SlotPicker } from "../components/SlotPicker";
import { useSafeNavigate } from "../hooks";
import { sendMessage } from "../iframe";
import { useIntl } from "../locale";

export const SchedulePage = () => {
  const { apiData } = useOutletContext<AppContext>();
  const navigate = useSafeNavigate();
  const intl = useIntl();

  const slots = apiData.invitationState.availableSlots ?? [];

  return (
    <Layout
      title={intl({
        en: "Pick an appointment",
        fr: "Choisir un rendez-vous",
        pt: "Escolha uma consulta",
      })}
    >
      {slots.length === 0 ? (
        <p className="text-center text-text-light">
          {intl({
            en: "No appointments available at the moment.",
            fr: "Aucun rendez-vous disponible pour le moment.",
            pt: "Nenhuma consulta disponível no momento.",
          })}
        </p>
      ) : (
        <SlotPicker
          slots={slots}
          onPick={(time: Date) => {
            sendMessage({ event: "selected_date" });
            navigate(`${time.getTime()}`);
          }}
        />
      )}
    </Layout>
  );
};
