import { useSearchParams } from "react-router-dom";

export enum DisplayMode {
  PAGE,
  EMBED,
}

export const SETTINGS_KEYS = ["display_mode", "minimized_rows"] as const;

export type Settings = {
  displayMode: DisplayMode;
  minimizedRows: number;
};

const DEFAULT_SETTINGS: Settings = {
  displayMode: DisplayMode.PAGE,
  minimizedRows: 10,
};

export const useSettings = (): Settings => {
  const [params] = useSearchParams();
  const get = (k: typeof SETTINGS_KEYS[number]) => params.get(k);
  return {
    displayMode:
      parseDisplayMode(get("display_mode")) ?? DEFAULT_SETTINGS.displayMode,
    minimizedRows:
      parseInteger(get("minimized_rows")) ?? DEFAULT_SETTINGS.minimizedRows,
  };
};

const parseDisplayMode = (s: string | null): DisplayMode | null => {
  switch (s) {
    case "page":
      return DisplayMode.PAGE;
    case "embed":
      return DisplayMode.EMBED;
    default:
      return null;
  }
};

const parseInteger = (s: string | null): number | null => {
  if (s === null) return null;
  return !isNaN(parseInt(s)) ? parseInt(s) : null;
};
