import {
  CalendarIcon,
  ClockIcon,
  PhoneIcon,
  UserIcon,
} from "@heroicons/react/outline";
import classNames from "classnames";
import React from "react";

import { useDateTimeIntl, useIntl } from "../locale";
import { startOfDay } from "../utils";

export const AppointmentDetails = ({
  startTime,
  endTime,
  hidePatientDetails,
  patientFirstName,
  patientLastName,
  patientPhone,
  doctorName,
  doctorTitle,
  doctorAvatarUrl,
  className,
}: {
  startTime: Date;
  endTime?: Date;
  hidePatientDetails?: boolean;
  patientFirstName: string;
  patientLastName: string;
  patientPhone: string | null;
  doctorName: string;
  doctorTitle: string | null;
  doctorAvatarUrl: string | null;
  className?: string;
}) => {
  const intl = useIntl();
  const { toFullDate, toHoursMinutes } = useDateTimeIntl();
  return (
    <div className="flex flex-grow">
      <div
        className={classNames(
          "flex flex-col sm:flex-row self-start sm:divide-x divide-divider space-y-9 sm:space-y-0 flex-grow text-text",
          className,
        )}
      >
        <div className="flex flex-col space-y-1 sm:pr-8">
          <h3 className="text-sm font-semibold mb-2">
            {intl({
              en: "Your appointment",
              fr: "Votre rendez-vous",
              pt: "Sua consulta",
            })}
          </h3>
          <div className="flex space-x-3 items-center pb-3">
            {doctorAvatarUrl && (
              <img
                src={doctorAvatarUrl}
                alt={doctorName}
                className="rounded-full w-14 h-14 overflow-hidden object-cover"
              />
            )}
            <div className="flex flex-col">
              <h4>{doctorName}</h4>
              {doctorTitle && (
                <p className="text-text-light font-light">{doctorTitle}</p>
              )}
            </div>
          </div>
          <div className="flex items-center space-x-1.5">
            <CalendarIcon height={20} />
            <div>
              {toFullDate(startTime)}
              {endTime &&
                startOfDay(startTime).getTime() !==
                  startOfDay(endTime).getTime() &&
                ` - ${toFullDate(endTime)}`}
            </div>
          </div>
          <div className="flex items-center space-x-1.5">
            <ClockIcon height={20} />
            <div>
              {toHoursMinutes(startTime)}
              {endTime && ` - ${toHoursMinutes(endTime)}`}
            </div>
          </div>
        </div>
        {!hidePatientDetails && (
          <div className="flex flex-col space-y-1 sm:pl-8">
            <h3 className="text-sm font-semibold mb-2">
              {intl({
                en: "Your details",
                fr: "Vos informations",
                pt: "Seus dados",
              })}
            </h3>
            <div className="flex items-center space-x-1.5">
              <UserIcon height={20} />
              <div>
                {patientFirstName} {patientLastName}
              </div>
            </div>
            {patientPhone && (
              <div className="flex items-center space-x-1.5">
                <PhoneIcon height={20} />
                <div>{patientPhone}</div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
