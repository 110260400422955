import { useEffect, useState } from "react";
import {
  NavigateFunction,
  NavigateOptions,
  parsePath,
  To,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import breakpoints, { ScreenName } from "./screens.config";
import { SETTINGS_KEYS } from "./settings";
import { mergeSearchParams } from "./utils";

/**
 * Returns an imperative method for changing the page location, while preserving
 * the current settings search parameters.
 */
export function useSafeNavigate(): NavigateFunction {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  return (toOrDelta: To | number, options?: NavigateOptions) => {
    if (typeof toOrDelta === "number") return navigate(toOrDelta);

    const partialPath =
      typeof toOrDelta === "string" ? parsePath(toOrDelta) : toOrDelta;

    const currentSettings = SETTINGS_KEYS.flatMap((key) => {
      const value = params.get(key);
      return value !== null ? [[key, value]] : [];
    });

    partialPath.search = mergeSearchParams(
      partialPath.search ?? "",
      currentSettings,
    ).toString();

    return navigate(partialPath, options);
  };
}

/**
 * Returns if the current screen size matches a Tailwind screen name.
 */
export function useMatchesScreen(breakpoint: ScreenName): boolean {
  const [matches, setMatches] = useState<boolean>(false);

  useEffect(() => {
    const mediaQuery = `(min-width: ${breakpoints[breakpoint]})`;
    const queryList = window.matchMedia(mediaQuery);
    const updateMatches = () => setMatches(queryList.matches);
    queryList.addEventListener("change", updateMatches);
    updateMatches();
    return () => queryList.removeEventListener("change", updateMatches);
  }, [breakpoint]);

  return matches;
}

/**
 * Returns the current date.
 */
export function useCurrentDate(): Date {
  const [date, setDate] = useState<Date>(new Date());

  useEffect(() => {
    const id = setInterval(() => setDate(new Date()), 1000);
    return () => clearInterval(id);
  });

  return date;
}
