import React, { useMemo, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";

import { AppContext } from "../App";
import { AppointmentDetails } from "../components/AppointmentDetails";
import { PrimaryButton } from "../components/Button";
import { Checkbox } from "../components/Checkbox";
import Layout from "../components/Layout";
import { useIntl } from "../locale";
import styles from "./scheduleConfirmConsents.module.css";

export const ScheduleConfirmPage = () => {
  const { apiData, mutations } = useOutletContext<AppContext>();
  const intl = useIntl();

  const { timestamp: rawTimestamp } = useParams();
  const timestamp = rawTimestamp ? parseInt(rawTimestamp) : undefined;

  const firstConsentHtml =
    apiData.configuration.confirmationConsents.firstConsentHtml.trim();
  const secondConsentHtml =
    apiData.configuration.confirmationConsents.secondConsentHtml.trim();

  const [firstConsentGranted, setFirstConsentGranted] = useState(
    firstConsentHtml === "",
  );
  const [secondConsentGranted, setSecondConsentGranted] = useState(
    secondConsentHtml === "",
  );

  const matchingSlot = useMemo(() => {
    const availableSlotsByDay = apiData.invitationState.availableSlots ?? [];
    const availableSlots = availableSlotsByDay.flatMap(({ slots }) => slots);
    return availableSlots.find(({ start }) => start.getTime() === timestamp);
  }, [apiData.invitationState.availableSlots, timestamp]);

  if (!matchingSlot) {
    return (
      <Layout
        title={intl({
          en: "Appointment unavailable",
          fr: "Rendez-vous indisponible",
          pt: "Consulta indisponível",
        })}
        showBack
      >
        <p className="text-center">
          {intl({
            en: "The appointment slot you have chosen is no longer available. Please go back to the calendar and pick another one.",
            fr: "Le créneau de rendez-vous que vous avez choisi n’est plus disponible. Veuillez revenir au calendrier et en choisir un nouveau.",
            pt: "O horário de consulta que você escolheu não está mais disponível. Volte ao calendário e escolha outro.",
          })}
        </p>
      </Layout>
    );
  }

  return (
    <Layout
      title={intl({
        en: "Confirm your appointment",
        fr: "Confirmer votre rendez-vous",
        pt: "Confirme sua consulta",
      })}
      showBack
    >
      <div className="flex flex-col max-w-lg w-full self-center flex-grow">
        <p className="text-text-light">
          {intl({
            en: "Please review the details of your appointment, then confirm it using the button below. You you will still be able to cancel it later on if needed.",
            fr: "Veuillez vérifier les détails de votre rendez-vous, puis confirmez-le avec le bouton ci-dessous. Il vous sera toujours possible de l’annuler plus tard.",
            pt: "Revise os detalhes da sua consulta e confirme-a usando o botão abaixo. Você ainda poderá cancelá-lo mais tarde, se necessário.",
          })}
        </p>

        <AppointmentDetails
          startTime={matchingSlot.start}
          endTime={matchingSlot.end}
          patientFirstName={apiData.patientSummary.firstName}
          patientLastName={apiData.patientSummary.lastName}
          patientPhone={apiData.patientSummary.phone}
          doctorName={matchingSlot.doctor.displayName}
          doctorTitle={matchingSlot.doctor.title}
          doctorAvatarUrl={matchingSlot.doctor.avatarUrl}
          className="my-10"
        />

        <div className="space-y-1">
          {firstConsentHtml !== "" && (
            <Checkbox
              id="consent-first"
              label={
                <div
                  className={styles.richText}
                  dangerouslySetInnerHTML={{
                    __html: firstConsentHtml,
                  }}
                />
              }
              checked={firstConsentGranted}
              onChange={(checked) => setFirstConsentGranted(checked)}
            />
          )}

          {secondConsentHtml !== "" && (
            <Checkbox
              id="consent-second"
              label={
                <div
                  className={styles.richText}
                  dangerouslySetInnerHTML={{
                    __html: secondConsentHtml,
                  }}
                />
              }
              checked={secondConsentGranted}
              onChange={(checked) => setSecondConsentGranted(checked)}
            />
          )}
        </div>

        <PrimaryButton
          label={intl({
            en: "Confirm the appointment",
            fr: "Confirmer le rendez-vous",
            pt: "Confirme a consulta",
          })}
          className="mt-6 mb-3"
          disabled={!firstConsentGranted || !secondConsentGranted}
          onClick={() => {
            mutations.pickSlot(matchingSlot.start, matchingSlot.doctor.uuid);
          }}
        />
      </div>
    </Layout>
  );
};

export const ConsentsCheckboxes = ({
  consents,
  onCheckChanged,
}: {
  consents: string[];
  onCheckChanged: (allChecked: boolean) => void;
}) => {
  const [checks, setChecks] = useState(consents.map(() => false));
  return (
    <div className="space-y-1">
      {consents.map((consent, index) => (
        <Checkbox
          id={`consent${index}`}
          label={
            <div
              className={styles.richText}
              dangerouslySetInnerHTML={{
                __html: consent,
              }}
            />
          }
          checked={checks[index]}
          onChange={(checked) => {
            const newChecks = checks.map((value, i) =>
              i === index ? checked : value,
            );
            setChecks(newChecks);
            onCheckChanged(newChecks.every((value) => value));
          }}
        />
      ))}
    </div>
  );
};
