import { DateTimeLocale } from "./locale";

/** List helpers. */
export const range = (start: number = 0, stop?: number, step: number = 1) => {
  let cur = stop === undefined ? 0 : start;
  let max = stop === undefined ? start : stop;
  let array = [];
  for (let i = cur; step < 0 ? i > max : i < max; i += step) array.push(i);
  return array;
};

/** Local storage helpers. */
export const getStorageItem = (key: string) => {
  try {
    return localStorage.getItem(key);
  } catch {
    // Under some circumstances (weird privacy settings), Chrome and Safari
    // will throw for "Security reasons" when querying local storage at page
    // initialization or inside an <iframe>.
    return null;
  }
};

/** Date and time helpers. */
export function toDate<T extends null | undefined>(date: string | T): Date | T {
  return date !== undefined && date !== null ? new Date(date) : date;
}

export const plusDays = (date: Date, days: number) => {
  let clonedDate = new Date(date.valueOf());
  clonedDate.setDate(clonedDate.getDate() + days);
  return clonedDate;
};

// Returns a date representing the nearest midnight before [date] in the
// browser's current timezone.
export const startOfDay = (date: Date) => {
  let clonedDate = new Date(date.valueOf());
  clonedDate.setHours(0, 0, 0, 0);
  return clonedDate;
};

// Returns the browser's current offset from UTC in the `[+-]hh:mm` form.
export const getTimezoneOffset = () => {
  const offset = new Date().getTimezoneOffset();
  const sign = offset > 0 ? "-" : "+"; // Sign is the other way around.
  const hours = Math.trunc(Math.abs(offset) / 60)
    .toString()
    .padStart(2, "0");
  const minutes = Math.trunc(Math.abs(offset) % 60)
    .toString()
    .padStart(2, "0");
  return `${sign}${hours}:${minutes}`;
};

export const toShortWeekdayLocalized = (locale: DateTimeLocale, date: Date) =>
  date.toLocaleDateString(locale, { weekday: "short" });

export const toShortMonthDayLocalized = (locale: DateTimeLocale, date: Date) =>
  date.toLocaleDateString(locale, { month: "short", day: "numeric" });

export const toFullDateLocalized = (locale: DateTimeLocale, date: Date) =>
  date.toLocaleDateString(locale, {
    month: "long",
    day: "numeric",
    year: "numeric",
  });

export const toHoursMinutesLocalized = (locale: DateTimeLocale, date: Date) =>
  date.toLocaleTimeString(locale, { hour: "2-digit", minute: "2-digit" });

/** String helpers. */
export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

/** Color helpers. */
export type HexColor = string;

export const hexColorToRgb = (color: HexColor) => {
  const int = parseInt(color.substring(1), 16);
  return [(int >> 16) & 255, (int >> 8) & 255, int & 255];
};

/** URL helpers. */
export const url = (
  path: string,
  params: Record<string, string> = {},
): string => {
  const url = new URL(path);
  Object.entries(params).forEach(([name, value]) =>
    url.searchParams.append(name, value),
  );
  return url.toString();
};

type SearchParamsInitializer =
  | string[][]
  | Record<string, string>
  | string
  | URLSearchParams;

export const mergeSearchParams = (
  ...args: SearchParamsInitializer[]
): URLSearchParams => {
  const params = new URLSearchParams();
  args.forEach((init) =>
    new URLSearchParams(init).forEach((value, key) => params.set(key, value)),
  );
  return params;
};
