import React, { ReactNode } from "react";

export const Checkbox = ({
  id,
  label,
  checked,
  onChange,
}: {
  id: string;
  label?: string | ReactNode;
  checked: boolean;
  onChange: (checked: boolean) => void;
}) => {
  return (
    <div className="relative flex items-center space-x-2">
      <input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        className="focus:ring-primary/40 h-4 w-4 text-primary/80 border-text-lighter/50 bg-transparent rounded"
      />
      {label && (
        <label htmlFor={id} className="text-text-light">
          {label}
        </label>
      )}
    </div>
  );
};
