import { useParticipant } from "@livekit/react-core";
import { Participant, Track } from "livekit-client";
import { memo, useEffect, useState } from "react";

import { useForceRerender } from "./hooks";
import { ReactComponent as LoaderIcon } from "./icons/loader.svg";
import { ReactComponent as VideoOffIcon } from "./icons/videoOff.svg";
import { flex, flexCenter, fullSize, transitionAll } from "./styles";

/**
 * Displays the video feed of a given–possibly undefined–participant.
 *
 * `maxWidth` and `maxHeight` can be used to constrain the maximum size of the
 * video using a CSS expression without percentages.
 */
export const LivekitParticipant = ({
  participant,
  maxWidth,
  maxHeight,
}: {
  participant: Participant | undefined;
  maxWidth?: string;
  maxHeight?: string;
}) => {
  const [aspectRatioState, setAspectRatio] = useState(1);

  const getWidthAndHeight = (
    aspectRatio: number,
  ): { width: string; height: string } => {
    // Allows the dimension of the video to transition smoothly.

    // The natural approach would be to use a combination of `aspect-ratio`,
    // `max-width` and `max-height`, but this doesn't work if any parent of
    // the <div> is using absolute positioning or is floating, because the
    // dimension of that parent would be computed using the "shrink-to-fit"
    // algorithm described in the CSS2.2 specification:
    // https://www.w3.org/TR/CSS22/visudet.html#abs-non-replaced-width
    //
    // In that scenario, the parent would take up the smallest amount of
    // space needed by its children, which would be 0 for this container
    // and the <video> it contains since we only define maximum constraints.
    //
    // Instead, we need to express the max-width and max-height constraints
    // as calculated widths, depending on the aspect ratio (width / height).
    //
    // - If the height is limiting, we will have:
    //     width = w1 := ratio * maxHeight
    //     height = h1 := maxHeight
    // - If the width is limiting, we will have:
    //     width = w2 := maxWidth
    //     height = h2 := 1 / ratio * maxWidth
    //
    // And since w1 < w2 <=> h1 < h2, we can just take the element-wise
    // minimum: width = min(w1, w2) and height = min(h1, h2).
    if (!maxWidth) return { width: "100%", height: maxHeight ?? "100%" };
    if (!maxHeight) return { width: maxWidth, height: "100%" };
    return {
      width: `calc(min(${aspectRatio} * ${maxHeight}, ${maxWidth}))`,
      height: `calc(min(${1 / aspectRatio} * ${maxWidth}, ${maxHeight}))`,
    };
  };

  return (
    <div
      style={{
        ...flex,
        flexGrow: 1,
        ...transitionAll,
        ...getWidthAndHeight(aspectRatioState),
      }}
    >
      {participant ? (
        <LivekitDefinedParticipant
          participant={participant}
          onDimensionsChange={(width, height) => {
            setAspectRatio(width / height);
          }}
        />
      ) : (
        <LivekitPlaceholder icon="loading" />
      )}
    </div>
  );
};

const LivekitDefinedParticipant = memo(
  ({
    participant,
    onDimensionsChange,
  }: {
    participant: Participant;
    onDimensionsChange?: (width: number, height: number) => void;
  }) => {
    const { cameraPublication, screenSharePublication, isLocal } =
      useParticipant(participant);

    // Screen sharing takes priority over the webcam.
    return screenSharePublication?.track && !screenSharePublication.isMuted ? (
      <LivekitVideoRenderer
        track={screenSharePublication.track}
        isLocal={false} // To avoid flipping the local screen share.
        onDimensionsChange={onDimensionsChange}
      />
    ) : cameraPublication?.track && !cameraPublication.isMuted ? (
      <LivekitVideoRenderer
        track={cameraPublication.track}
        isLocal={isLocal}
        onDimensionsChange={onDimensionsChange}
      />
    ) : (
      <LivekitPlaceholder icon="unavailable" />
    );
  },
);

const LivekitVideoRenderer = ({
  track,
  isLocal,
  onDimensionsChange,
}: {
  track: Track;
  isLocal: boolean;
  onDimensionsChange?: (width: number, height: number) => void;
}) => {
  const forceRerender = useForceRerender();
  const [ref, setRef] = useState<HTMLVideoElement | null>(null);

  // Attaches the track to the current <video> element.
  useEffect(() => {
    if (!ref) return;
    ref.muted = true;
    track.attach(ref);
    return () => {
      track.detach(ref);
    };
  }, [ref, track]);

  // Observes intrinsic dimension changes of the video.
  useEffect(() => {
    if (!ref) return;
    const onChange = () => {
      onDimensionsChange?.(ref.videoWidth, ref.videoHeight);
    };
    ref.addEventListener("resize", onChange);
    ref.addEventListener("loadedmetadata", onChange);
    return () => {
      ref.removeEventListener("resize", onChange);
      ref.removeEventListener("loadedmetadata", onChange);
    };
  }, [ref, onDimensionsChange]);

  // Re-renders the component whenever the underlying MediaStreamTrack changes.
  //
  // Needed because the `useParticipant` hook doesn't trigger a rerender when
  // the underlying track changes.
  useEffect(() => {
    if (!ref) return;
    ref.addEventListener("loadstart", forceRerender);
    return () => {
      ref.removeEventListener("loadstart", forceRerender);
    };
  }, [ref, forceRerender]);

  const isFrontFacing =
    isLocal &&
    track.mediaStreamTrack.getSettings().facingMode !== "environment";

  return (
    <video
      ref={setRef}
      style={{
        // Mirrors the self-preview.
        // Uses the `scaleX` 2D transform instead of the `rotateY` 3D transform
        // to avoid https://github.com/twilio/twilio-video.js/issues/1724.
        transform: isFrontFacing ? "scaleX(-1)" : "",

        // Allows the video to be resized past its initial size.
        display: "block",
        maxWidth: "100%",
        maxHeight: "100%",
        width: "auto",
        height: "auto",
        flex: "1 1 0",
      }}
    />
  );
};

const LivekitPlaceholder = ({ icon }: { icon: "loading" | "unavailable" }) => (
  <div
    style={{
      ...fullSize,
      ...flexCenter,
      color: "#262626ff",
      backgroundColor: "#525252ff",
    }}
  >
    {icon === "loading" ? (
      <LoaderIcon style={{ width: 60 }} />
    ) : (
      <VideoOffIcon style={{ width: 60 }} />
    )}
  </div>
);
