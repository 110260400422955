import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from "react";

import {
  toFullDateLocalized,
  toHoursMinutesLocalized,
  toShortMonthDayLocalized,
  toShortWeekdayLocalized,
} from "./utils";

export type Locale = "FRENCH" | "ENGLISH" | "PORTUGUESE";

const LocaleContext = createContext<Locale | null>(null);
LocaleContext.displayName = "LocaleContext";

export const LocaleContextProvider = ({
  rawLocale,
  children,
}: {
  rawLocale: string;
  children: ReactNode;
}) => (
  <LocaleContext.Provider
    value={(() => {
      switch (rawLocale) {
        case "FRENCH":
          return "FRENCH";
        case "PORTUGUESE":
          return "PORTUGUESE";
        default:
          return "ENGLISH";
      }
    })()}
  >
    {children}
  </LocaleContext.Provider>
);

const getBrowserLanguage = () =>
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.language;

export const getBrowserLocale = (): Locale =>
  getBrowserLanguage().toLowerCase().startsWith("fr")
    ? "FRENCH"
    : getBrowserLanguage().toLowerCase().startsWith("pt")
    ? "PORTUGUESE"
    : "ENGLISH";

export const useLocale = () => useContext(LocaleContext) ?? getBrowserLocale();

export const useIntl = () => {
  const locale = useLocale();
  return useCallback(
    ({ en, fr, pt }: { en: string; fr: string; pt: string }) => {
      switch (locale) {
        case "ENGLISH":
          return en;
        case "FRENCH":
          return fr;
        case "PORTUGUESE":
          return pt;
      }
    },
    [locale],
  );
};

// We don't store whether patients are American or British at the moment,
// but we need this information to format dates and times properly, so we
// try to infer it from the browser's full locale instead.
export type DateTimeLocale = "fr-FR" | "en-US" | "en-GB" | "pt-BR" | "pt-PT";

const useDateTimeLocale = (): DateTimeLocale => {
  switch (useLocale()) {
    case "FRENCH":
      return "fr-FR";
    case "ENGLISH":
      return getBrowserLanguage().toLowerCase().startsWith("en-gb")
        ? "en-GB"
        : "en-US";
    case "PORTUGUESE":
      return getBrowserLanguage().toLowerCase().startsWith("pt-pt")
        ? "pt-PT"
        : "pt-BR";
  }
};

export const useDateTimeIntl = () => {
  const locale = useDateTimeLocale();
  return useMemo(
    () => ({
      toShortWeekday: (date: Date) => toShortWeekdayLocalized(locale, date),
      toShortMonthDay: (date: Date) => toShortMonthDayLocalized(locale, date),
      toFullDate: (date: Date) => toFullDateLocalized(locale, date),
      toHoursMinutes: (date: Date) => toHoursMinutesLocalized(locale, date),
    }),
    [locale],
  );
};
