import classNames from "classnames";
import React, { CSSProperties, useEffect } from "react";

import { Theme } from "../api";
import { useIntl } from "../locale";
import { DisplayMode, useSettings } from "../settings";
import { HexColor, hexColorToRgb } from "../utils";

export const Page = ({
  organizationName,
  logoUrl,
  faviconUrl,
  theme,
  children,
}: {
  organizationName?: string;
  logoUrl?: string | null;
  faviconUrl?: string | null;
  theme?: Theme | null;
  children: React.ReactNode;
}) => {
  const intl = useIntl();
  const { displayMode } = useSettings();
  const isPageMode = displayMode === DisplayMode.PAGE;
  const isLogoShown = isPageMode && logoUrl;

  // Dynamically update the title and favicon.
  useEffect(() => {
    const localizedTitle = intl({
      en: "Book an appointment",
      fr: "Prendre un rendez-vous",
      pt: "Marcar uma consulta",
    });

    document.title = organizationName
      ? `${localizedTitle} · ${organizationName}`
      : localizedTitle;

    const link = document.querySelector("link[rel=icon]") as HTMLLinkElement;
    link.href = faviconUrl ?? "";
  }, [intl, organizationName, faviconUrl]);

  return (
    <main
      className={classNames("min-h-full flex bg-background", {
        "sm:flex-center": isPageMode,
      })}
      style={themeVariables(theme)}
    >
      <div
        className={classNames("flex flex-col", {
          "container sm:space-y-9 sm:my-9": isPageMode,
          "w-full": !isPageMode,
        })}
      >
        {isLogoShown && (
          <div className="z-0 fixed left-0 right-0 mt-[19px] sm:mt-0 sm:relative h-6 sm:h-8 flex-center">
            <img src={logoUrl} className="h-full" alt="Logo" />
          </div>
        )}
        <div
          className={classNames("z-10 justify-center flex-grow flex flex-col", {
            "pt-16 sm:pt-0": isLogoShown,
            "sm:flex-grow-0": isPageMode,
          })}
        >
          {children}
        </div>
      </div>
    </main>
  );
};

export interface ThemeVariables extends CSSProperties {
  "--color-primary": string;
  "--color-text": string;
  "--color-text-light": string;
  "--color-text-lighter": string;
  "--color-divider": string;
  "--color-background": string;
  "--color-card": string;
}

const themeVariable = (color: HexColor) => hexColorToRgb(color).join(" ");

const themeVariables = (theme?: Theme | null): ThemeVariables | undefined => {
  if (!theme) return;
  return {
    "--color-primary": themeVariable(theme.primaryColor),
    "--color-text": themeVariable(theme.textColor),
    "--color-text-light": themeVariable(theme.textLightColor),
    "--color-text-lighter": themeVariable(theme.textLighterColor),
    "--color-divider": themeVariable(theme.dividerColor),
    "--color-background": themeVariable(theme.backgroundColor),
    "--color-card": themeVariable(theme.cardColor),
  };
};
